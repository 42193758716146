"use client";

import { Provider } from "react-redux";
import { makeStore } from "../../lib/store";
import { NextIntlClientProvider } from "next-intl";
import ko from "@/messages/ko.json";
import en from "@/messages/en.json";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SessionProvider } from "next-auth/react";
import { LoadingProvider } from "./LoadingProvider";
import { Analytics } from "@vercel/analytics/react";
import { PersistGate } from "redux-persist/integration/react";
import { useSessionPersistence } from "@/lib/utils/customHook/useSessionPersistence";
import Loading from "@/components/ui/loading";
import useHydrated from "@/lib/utils/customHook/useHydrated";
export default function StoreProvider({
  children,
  locale = "ko"
}: {
  children: React.ReactNode;
  locale?: string;
}) {
  const {
    store,
    persistor
  } = makeStore();
  useSessionPersistence(persistor);
  const isHydrated = useHydrated(persistor);
  if (!isHydrated) {
    return <Loading />; // Hydration이 완료되기 전 로딩 화면 표시
  }
  return <NextIntlClientProvider locale={locale} messages={locale === "en" ? ko : ko} data-sentry-element="NextIntlClientProvider" data-sentry-component="StoreProvider" data-sentry-source-file="StoreProvider.tsx">
      <LoadingProvider data-sentry-element="LoadingProvider" data-sentry-source-file="StoreProvider.tsx">
        <SessionProvider refetchInterval={0} // Disables automatic session refreshing
      refetchOnWindowFocus={false} // Disables refetching on window focus
      data-sentry-element="SessionProvider" data-sentry-source-file="StoreProvider.tsx">
          <DndProvider backend={HTML5Backend} data-sentry-element="DndProvider" data-sentry-source-file="StoreProvider.tsx">
            <Analytics data-sentry-element="Analytics" data-sentry-source-file="StoreProvider.tsx" />
            <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="StoreProvider.tsx">
              <PersistGate loading={<Loading />} persistor={persistor} data-sentry-element="PersistGate" data-sentry-source-file="StoreProvider.tsx">
                {children}
              </PersistGate>
            </Provider>
          </DndProvider>
        </SessionProvider>
      </LoadingProvider>
    </NextIntlClientProvider>;
}