"use client";

import React, { createContext, useContext, useState } from "react";
const LoadingContext = createContext({
  loadingList: ([] as string[]),
  startLoading: (value: string) => {},
  stopLoading: (value: string) => {}
});
export const useLoading = () => {
  return useContext(LoadingContext);
};
export const LoadingProvider = ({
  children
}: any) => {
  const [loadingList, setLoadingList] = useState<string[]>([]);
  const startLoading = (value: string) => setLoadingList([...loadingList, value]);
  const stopLoading = (value: string) => setLoadingList(prev => [...prev.filter(v => v !== value)]);
  return <LoadingContext.Provider value={{
    loadingList,
    startLoading,
    stopLoading
  }} data-sentry-element="unknown" data-sentry-component="LoadingProvider" data-sentry-source-file="LoadingProvider.tsx">
      {children}
    </LoadingContext.Provider>;
};