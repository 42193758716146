"use client";

import { Inter } from "next/font/google";
import "@/styles/globals.css";
import { cn } from "@/lib/utils/math";
import StoreProvider from "./StoreProvider";
import { useLocale } from "next-intl";
import { useEffect } from "react";
import gtag from "react-ga4";
import { usePathname, useSearchParams } from "next/navigation";
const inter = Inter({
  subsets: ["latin"],
  variable: "--font-sans"
});
export default function RootLayout({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const locale = useLocale();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    const initializeGA = () => {
      try {
        gtag.initialize("G-05304XVVCV");
      } catch (error) {
        console.error("Error initializing Google Analytics", error);
      }
    };
    const trackPageView = (url: string) => {
      try {
        gtag.set({
          page: url
        });
        gtag.send("pageview");
      } catch (error) {
        console.error("Error tracking pageview with Google Analytics", error);
      }
    };
    initializeGA();
    const initialUrl = window.location.pathname + window.location.search;
    trackPageView(initialUrl);
    if (!searchParams) {
      console.error("SearchParams is not defined");
      return;
    }
    trackPageView(pathname + searchParams.toString());
  }, [pathname, searchParams]);
  return <html lang={locale} className="h-screen" data-sentry-component="RootLayout" data-sentry-source-file="layout.tsx">
      <body className={cn("h-screen w-screen overflow-hidden font-sans antialiased flex flex-col bg-gray-100 dark:bg-gray-900 dark:text-white dark:font-sans", inter.variable)}>
        <StoreProvider locale={locale} data-sentry-element="StoreProvider" data-sentry-source-file="layout.tsx">{children}</StoreProvider>
      </body>
    </html>;
}