import { useEffect } from "react";
import { Persistor } from "redux-persist";

const SESSION_KEY = "reduxPersistTabActive";

export const useSessionPersistence = (persistor: Persistor) => {
  useEffect(() => {
    // Initialize session state
    if (!sessionStorage.getItem(SESSION_KEY)) {
      sessionStorage.setItem(SESSION_KEY, "active");
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Tab is hidden
        sessionStorage.setItem(SESSION_KEY, "hidden");
      } else if (document.visibilityState === "visible") {
        // Tab is visible
        if (sessionStorage.getItem(SESSION_KEY) === "hidden") {
          sessionStorage.setItem(SESSION_KEY, "active");
        }
      }
    };

    const handlePageUnload = () => {
      // Clear session state on tab close
      sessionStorage.removeItem(SESSION_KEY);
      persistor.purge(); // Clear Redux Persist state
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handlePageUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handlePageUnload);
    };
  }, [persistor]);
};
