import { useState, useEffect } from "react";
import { Persistor } from "redux-persist";

const useHydrated = (persistor: Persistor): boolean => {
  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    // Hydration 완료 시 상태 변경
    const handleRehydrate = () => setIsHydrated(true);

    // 아직 bootstrapped되지 않은 경우 구독
    if (!persistor.getState().bootstrapped) {
      const unsubscribe = persistor.subscribe(() => {
        if (persistor.getState().bootstrapped) {
          handleRehydrate();
        }
      });
      return unsubscribe; // 컴포넌트가 언마운트될 때 구독 해제
    } else {
      // 이미 bootstrapped된 경우 즉시 처리
      handleRehydrate();
    }
  }, [persistor]);

  return isHydrated;
};

export default useHydrated;
